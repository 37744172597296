



























































































































import { Component, Vue } from "vue-property-decorator";
import SublimeOfficeDTO from "@/dto/sublime/office/SublimeOfficeDTO";
import { namespace } from "vuex-class";
import AdminService from "@/services/AdminService";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import { STATE_LIST } from "@/constants/ProfileConstants";
import PhoneInput from "@/components/util/PhoneInput.vue";
import SublimeOfficeUpdateRequest from "@/dto/sublime/office/SublimeOfficeUpdateRequest";
import SublimeEmployeeDTO from "@/dto/sublime/employee/SublimeEmployeeDTO";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component({
    components: { PhoneInput }
})
export default class SublimeOffice extends Vue {
    private request: SublimeOfficeUpdateRequest = new SublimeOfficeUpdateRequest();

    private employees: Array<SublimeEmployeeDTO> = [];

    private message = "";

    private successful = false;

    private updateFlag = false;

    private states = STATE_LIST;

    @AppModule.Action
    private startLoading!: () => void

    @AppModule.Action
    private stopLoading!: () => void

    @Auth.Getter("hasAdministrativePrivileges")
    private hasAdministrativePrivileges!: boolean;

    created() {
        // Todo: Расскоментировать, когда поправим права на фронте
        // if (!this.hasAdministrativePrivileges) {
        //     this.$router.push({name: RouteNames.HOME});
        // }
    }

    mounted() {
        this.loadOffice();
    }

    private loadOffice(): void {
        this.startLoading();
        AdminService.getOffice(this.$route.params.officeId as string).then(
            res => this.processResp(res.data),
            err => {
                this.message = this.$t(ErrorLocalePathUtils.getPathForError(err)) as string;
                this.successful = false;
                this.stopLoading();
            }
        )
    }

    private processResp(office: SublimeOfficeDTO) {
        this.updateFlag = true;
        this.request.phone = office.phone;
        this.request.title = office.title;
        this.employees = office.employees;
        if (office.address) {
            this.request.address.address1 = office.address.address1;
            this.request.address.zipCode = office.address.zipCode;
            this.request.address.city = office.address.city;
            this.request.address.state = office.address.state;
        }
        this.stopLoading();
    }

    private zipCodeHandler(): void {
        if (this.request.address?.zipCode) {
            if (this.request.address.zipCode.length > 5) {
                this.request.address.zipCode = this.request.address.zipCode.substr(0, 5);
            }
        }
    }

    private update(): void {
        this.message = "";
        this.$validator.validateAll().then(
            isValid => {
                if (isValid) {
                    if (this.request.phone!.length < 7) {
                        this.request.phone = null;
                    }
                    this.request.officeId = Number.parseInt(this.$route.params.officeId, 10);
                    this.startLoading();
                    AdminService.updateSublimeOffice(this.request).then(
                        () => {
                            this.stopLoading();
                        },
                        err => {
                            this.message = this.$t(ErrorLocalePathUtils.getPathForError(err)) as string;
                            this.successful = false;
                            this.stopLoading();
                        }
                    )
                }
            }
        )
    }
};
